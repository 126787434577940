// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login._index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login._index.tsx");
  import.meta.hot.lastModified = "1709112347291.6187";
}
// REMIX HMR END

import { json } from "@remix-run/node";
import { useActionData, useLoaderData } from "@remix-run/react";
import { Login } from "~/components/login";
import { registerLinks as loginContainer } from "~/components/login";
import { userPrefs } from "~/cookies.server";
import { FETCH_METHODS } from "~/utils/fetchUtils";
import { authWithJWT, fetchFromBackendServer } from "~/utils/fetchUtils.server";
export const links = () => [...loginContainer()];
export const sitemap = () => ({
  exclude: true
});
export const headers = ({
  loaderHeaders
}) => ({
  "Cache-Control": loaderHeaders.get("Cache-Control") || "no-cache"
});
export async function loader({
  params
}) {
  return json({});
}
export async function action({
  request
}) {
  const formData = await request.formData();
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/auth/local`;
  const res = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      identifier: formData.get("email"),
      password: formData.get("password")
    })
  });
  const response = await res.json();
  if (response.jwt) {
    const user = await authWithJWT(response.jwt);
    const cookieHeader = request.headers.get("Cookie");
    let cookie = (await userPrefs.parse(cookieHeader)) || {};
    cookie = {
      ...cookie,
      user
    };

    // return login response
    return json(user, {
      headers: {
        "Set-Cookie": await userPrefs.serialize(cookie),
        "Cache-Control": "no-cache"
      }
    });
  } else {
    if (process.env.REACT_STRAPI_ACCESS_TOKEN && formData.get("email")) {
      const {
        result,
        status
      } = await fetchFromBackendServer(process.env.REACT_STRAPI_ACCESS_TOKEN, FETCH_METHODS.ENTRIES, "users", undefined, undefined, {
        filters: {
          email: {
            $eq: formData.get("email")
          }
        }
      });
      console.log(result);
      if (result && result.length == 1) {
        const userExists = result[0];
        if (!userExists.blocked && userExists.confirmed && userExists.lastLogin === null) {
          // Need Reset Password

          const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`;
          const res = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: formData.get("email")
            })
          });
          await res.json();
          return json({
            passwordReset: true
          }, {
            headers: {
              "Cache-Control": "no-cache"
            }
          });
        }
      }
    }
  }

  // return login response
  return response;
}
export default function Index() {
  _s();
  const data = useLoaderData();
  const dataAction = useActionData();
  return <Login />;
}
_s(Index, "A7xbhOkJEa0M9L2huU59shw1wmo=", false, function () {
  return [useLoaderData, useActionData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;